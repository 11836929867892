import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
// import { BreadcrumbService, Breadcrumb } from 'angular-crumbs';
import {DOCUMENT, isPlatformBrowser, Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { AppSettings, Settings } from './app.settings';
import { TokenStorageService } from './_services/token-storage.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit {
  private roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username?: string;

  title: string;
  language: string;
  public settings: Settings;
  lang:string;
  constructor(private titleService: Title,public translate: TranslateService, public appSettings:AppSettings, private tokenStorageService: TokenStorageService,public router:Router, @Inject(PLATFORM_ID) private platformId: Object) {
    this.settings = this.appSettings.settings;
    this.lang = localStorage.getItem('lang');
    translate.addLangs(['en','ar']);
    translate.setDefaultLang('en');
    translate.use(this.lang);
    if(this.lang == 'ar'){
      this.appSettings.settings.rtl = true;
    } else {
      this.appSettings.settings.rtl = false;
    }

    // private breadcrumbService:
  }


  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;

      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');

      this.username = user.username;
    }
  }

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0,0);
          }
        });
      }
    });
  }

  onActivate(event){
    window.scroll(0,0);
  }


  // private createTitle(routesCollection: Breadcrumb[]) {
  //   const title = 'Yachts Angular';
  //   const titles = routesCollection.filter((route) => route.displayName);

  //   if (!titles.length) { return title; }

  //   const routeTitle = this.titlesToString(titles);
  //   return `${routeTitle} ${title}`;
  // }

  private titlesToString(titles) {
    return titles.reduce((prev, curr) => {
      return `${curr.displayName} - ${prev}`;
    }, '');
  }
}
