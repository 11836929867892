import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const USER_PRO = 'user-profile'

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {
    constructor() { 
        window.addEventListener('storage', this.handleStorageEvent, false);

         this.requestSyncSessionStorage();
    }

    handleStorageEvent = (event: StorageEvent): void => {
        if (event.key === 'requestSyncSessionStorage') {
          console.log('handleStorageEvent - requestSyncSessionStorage', event);
          localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
          localStorage.removeItem('sessionStorage');
        } else if (event.key === 'sessionStorage') {
          console.log('handleStorageEvent - sessionStorage', event);
          const sessionStorage = JSON.parse(event.newValue || '{}');
          for (const key in sessionStorage) {
            window.sessionStorage.setItem(key, sessionStorage[key]);
          }
        }
      };
    
      requestSyncSessionStorage(): void {
        console.log('requestSyncSessionStorage - sessionStorage', sessionStorage);
        if (!sessionStorage.length) {
          const current = new Date().toLocaleTimeString();
          console.log('requestSyncSessionStorage - request', current);
          localStorage.setItem(
            'requestSyncSessionStorage',
            'request session storage' + current
          );
        }
      }

    signOut(): void {
        window.sessionStorage.clear();
    }

    public saveToken(token: string): void {
        window.sessionStorage.removeItem(TOKEN_KEY);
        window.sessionStorage.setItem(TOKEN_KEY, token);
    }

    public getToken(): string | null {
        return window.sessionStorage.getItem(TOKEN_KEY);
    }

    public saveUser(user: any): void {
        window.sessionStorage.removeItem(USER_KEY);
        window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    }
    public saveProfile(profile: any): void {
        window.sessionStorage.removeItem(USER_PRO);
        window.sessionStorage.setItem(USER_PRO, JSON.stringify(profile))
    }

    public getProfile():any {
        const profile = window.sessionStorage.getItem(USER_PRO);
        if (profile) {
            return JSON.parse(profile);
        }
        return {};
    }

    public getUser(): any {
        const user = window.sessionStorage.getItem(USER_KEY);
        if (user) {
            return JSON.parse(user);
        }

        return {};
    }
}