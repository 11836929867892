import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

const AUTH_API = 'https://yachts-ksa.com/api/v1/auth/';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private http: HttpClient) { }

    login(username: string, password: string): Observable<any> {
        return this.http.post(AUTH_API + 'signin', {
            username,
            password
        }, httpOptions);
    }

    register(username: string, email: string, password: string, prenom: string, lastname: string,type:string): Observable<any> {
      let joinDate=new Date().toJSON().slice(0,10).replace(/-/g,'/');
      return this.http.post(AUTH_API + 'signup', {
            username,
            email,
            password,
            prenom,
            lastname,
            joinDate,
            type,
            roles:['user','mod']
        }, httpOptions);
    }
}
