import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_services/auth.guard';


 const  routes: Routes = [
  // Hompages
  { path: '', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), data: { breadcrumb: 'Homepage' } },
  // Blog
  { path: 'blog/cat/:catId', loadChildren: () => import('./components/pages/bloggrid/bloggrid.module').then(m => m.BloggridModule), data: { breadcrumb: 'Blog Grid' } },
  { path: 'blog/tag/:tagId', loadChildren: () => import('./components/pages/bloggrid/bloggrid.module').then(m => m.BloggridModule), data: { breadcrumb: 'Blog Grid' } },
  { path: 'blog/author/:authorId', loadChildren: () => import('./components/pages/bloggrid/bloggrid.module').then(m => m.BloggridModule), data: { breadcrumb: 'Blog Grid' } },
  { path: 'blog', loadChildren: () => import('./components/pages/bloggrid/bloggrid.module').then(m => m.BloggridModule), data: { breadcrumb: 'Blog Grid' } },
  { path: 'post/:id', loadChildren: () => import('./components/pages/blogsingle/blogsingle.module').then(m => m.BlogsingleModule), data: { breadcrumb: 'Blog Details' } },
  // Pages
  { path: 'about', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: 'AboutUs' } },
  { path: 'services', loadChildren: () => import('./components/pages/services/services.module').then(m => m.ServicesModule), data: { breadcrumb: 'Services' } },
  { path: 'faq', loadChildren: () => import('./components/pages/faq/faq.module').then(m => m.FaqModule), data: { breadcrumb: "FAQ's" } },
  { path: 'pricing', loadChildren: () => import('./components/pages/pricing/pricing.module').then(m => m.PricingModule), data: { breadcrumb: 'Pricing' } },
  { path: 'contact', loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'ContactUs' } },
  { path: 'coming-soon', loadChildren: () => import('./components/pages/comingsoon/comingsoon.module').then(m => m.ComingsoonModule), data: { breadcrumb: 'Coming Soon' } },
  { path: 'error', loadChildren: () => import('./components/pages/error/error.module').then(m => m.ErrorModule), data: { breadcrumb: 'Error 404' } },
  { path: 'login', loadChildren: () => import('./components/pages/login/login.module').then(m => m.LoginModule), data: { breadcrumb: 'Login' }},
  { path: 'register', loadChildren: () => import('./components/pages/register/register.module').then(m => m.RegisterModule), data: { breadcrumb: 'Register' } },
  { path: 'legal', loadChildren: () => import('./components/pages/legal/legal.module').then(m => m.LegalModule), data: { breadcrumb: 'Legal' } },
  // Yachts
  { path: 'map/charter', loadChildren: () => import('./components/pages/chartermap/chartermap.module').then(m => m.ChartermapModule), data: { breadcrumb: 'Chartergrid' } },
  { path: 'map/buy', loadChildren: () => import('./components/pages/buyrmap/buyrmap.module').then(m => m.BuyrmapModule), data: { breadcrumb: 'Buygrid' } },
  { path: 'map/swapping', loadChildren: () => import('./components/pages/swappingrmap/swappingrmap.module').then(m => m.SwappingrmapModule), data: { breadcrumb: 'Swappinggrid' } },
  { path: 'grids/charter', loadChildren: () => import('./components/pages/chartergrid/chartergrid.module').then(m => m.ChartergridModule), data: { breadcrumb: 'Chartergrid' } },
  { path: 'grids/buy', loadChildren: () => import('./components/pages/buygrid/buygrid.module').then(m => m.BuygridModule), data: { breadcrumb: 'Buygrid' } },
  { path: 'grids/swapping', loadChildren: () => import('./components/pages/swappinggrid/swappinggrid.module').then(m => m.SwappinggridModule), data: { breadcrumb: 'Swappinggrid' } },
  { path: 'yacht/:id', loadChildren: () => import('./components/pages/listingdetail/listingdetail.module').then(m => m.ListingdetailModule), data: { breadcrumb: 'Listing Details' } },
  { path: 'submit-yacht', loadChildren: () => import('./components/pages/submitlisting/submitlisting.module').then(m => m.SubmitlistingModule), data: { breadcrumb: 'SubmitYacht' }, canActivate: [AuthGuard] },
  { path: 'edityacht/:id', loadChildren: () => import('./components/pages/edityacht/edityacht.module').then(m => m.EdityachtModule), data: { breadcrumb: 'EditYacht' }, canActivate: [AuthGuard] },
  { path: 'compare-yachts', loadChildren: () => import('./components/pages/comparelisting/comparelisting.module').then(m => m.ComparelistingModule), data: { breadcrumb: 'Compare Yachts' } },
  // Agents
  { path: 'agent-archive', loadChildren: () => import('./components/pages/agentarchive/agentarchive.module').then(m => m.AgentarchiveModule), data: { breadcrumb: 'Agent Archive' } },
  { path: 'agent-details/:id', loadChildren: () => import('./components/pages/agentdetails/agentdetails.module').then(m => m.AgentdetailsModule), data: { breadcrumb: 'Agent Details' } },
  { path: 'profile', loadChildren: () => import('./components/pages/profile/profile.module').then(m => m.ProfileModule), data: { breadcrumb: 'Profile' }, canActivate: [AuthGuard] },
  { path: 'profile-yachts', loadChildren: () => import('./components/pages/profilelisting/profilelisting.module').then(m => m.ProfilelistingModule), data: { breadcrumb: 'My Yachts' }, canActivate: [AuthGuard] },
  { path: 'favorite', loadChildren: () => import('./components/pages/profilesaved/profilesaved.module').then(m => m.ProfilesavedModule), data: { breadcrumb: 'Favorite Yachts' }, canActivate: [AuthGuard] },
  // Agency
  { path: 'agency-archive', loadChildren: () => import('./components/pages/agencyarchive/agencyarchive.module').then(m => m.AgencyarchiveModule), data: { breadcrumb: 'Agency Archive' } },
  { path: 'agency-details/:id', loadChildren: () => import('./components/pages/agencydetails/agencydetails.module').then(m => m.AgencydetailsModule), data: { breadcrumb: 'Agency Details' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
