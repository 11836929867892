import { Injectable } from '@angular/core';

export class Settings {
    constructor(public name: string,
                public header: string,
                public rtl: boolean,
                public currency: string,
                public searchOnBtnClick: boolean,
                public loadMore: {
                  start: boolean,
                  step: number,
                  load: boolean,
                  page: number,
                  complete: boolean,
                  result: number
                }
    ) { }
}

@Injectable()
export class AppSettings {
    public settings = new Settings(
        'Dari',       // theme name
        'image',      // default, image, carousel, map, video
         false,       // true = rtl, false = ltr
        'USD',// USD, EUR
         true,
      {
        start: false,
        step: 1,
        load: false,
        page: 1,
        complete: false,
        result: 0
      }
    )
}
